import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
const baseURL =
  process.env.NODE_ENV === "production"
    ? "https://mc.autoplusrw.com/api/"
    : "http://127.0.0.1:8000/api/";
axios.defaults.baseURL = baseURL;
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    status: "",
    timeZone: "Africa/Kigali",
    token: localStorage.getItem("token") || "",
    branch: JSON.parse(localStorage.getItem("__branch")) || {},
    user: {},
    isLoading: false,
    requestFlag: null,
    drawerOpen: true,
    entityImagePost: {
      record_id: null,
      url: null,
    },
    deleteAlert: {
      title: null,
      content: null,
      actionType: null,
      actionButton: null,
      classes: null,
      modalOpen: false,
    },
    recordDeleter: {
      entityName: null,
      url: null,
      media: {
        recordImage: null,
        galleryTable: null,
        mediaPath: null,
        galleryParentRef: null,
      },
    },
    currentContent: {},
    responseData: {},
    hasActiveModal: false,
    flashMessage: {},
    branches: [],
    settings: {
      base_color: null,
      secondary_color: null,
      site_logo: null,
      favicon: null,
      site_address: null,
      site_name: null,
      currency: null,
      contact_one: null,
      app_phone: null,
      app_email: null,
      app_tin: null,
    },
    notifications: {
      requisitions: 0,
      purchase_orders: 0,
      suppliers_payments: 0,
      pending_orders: 0,
    },
  },

  mutations: {
    SET_APP_SETTINGS(state, settings) {
      state.settings = settings;
    },
    SET_LOCKED_BLANCH(state, branch) {
      if (branch != null)
        localStorage.setItem("__branch", JSON.stringify(branch));
      else localStorage.removeItem("__branch");
      state.branch = branch;
    },
    SET_REQUEST_FLAG(state, flag) {
      state.requestFlag = flag;
    },
    SET_FLASH_MESSAGE(state, message) {
      state.flashMessage = message;
    },
    AUTH_REQUEST(state) {
      state.status = "loading";
    },
    AUTH_SUCCESS(state, playload) {
      state.status = "success";
      state.token = playload.token;
      state.user = playload.user;
      state.requestFlag = null;
    },
    REQUEST_SUCCESS(state) {
      state.requestFlag = null;
      if (state.hasActiveModal) {
        state.hasActiveModal = false;
        document.querySelector("#alert-container").remove();
      }
    },
    REQUEST_ERROR(state) {
      state.status = "error";
      state.requestFlag = null;
      if (state.hasActiveModal) {
        state.hasActiveModal = false;
        document.querySelector("#alert-container").remove();
      }
    },
    logout(state) {
      state.status = "";
      state.token = "";
    },
    SET_POST_DATA(state, args) {
      state.entityImagePost = {
        record_id: args.record_id,
        url: args.url,
      };
    },
    SET_CURRENT_USER(state, user) {
      localStorage.removeItem("__branch");
      state.user = user;
      if (user.branch) {
        localStorage.setItem("__branch", JSON.stringify(user.branch));
      }
    },
    SET_DB_RESPONSE(state, responseData) {
      if (responseData) {
        state.responseData = { ...state.responseData, ...responseData };
      }
    },
    SET_BRANCHES(state, branches) {
      state.branches = branches;
    },
    SET_MENU_NOTIFICATIONS(state, records) {
      state.notifications = { ...state.notifications, ...records };
    },
    REDUCE_NOTIFICATIONS(state, key){
      state.notifications[key] -= 1;
    }
  },
  actions: {
    SetEntityPostData({ commit }, args) {
      return new Promise((resolve) => {
        commit("SET_POST_DATA", args);
        resolve();
      });
    },
    authRequest({ commit }, dataParams) {
      return new Promise((resolve, reject) => {
        commit("AUTH_REQUEST");
        axios({
          url: dataParams.url,
          data: dataParams.formData,
          method: "POST",
        })
          .then((resp) => {
            const token = resp.data.access_token;
            if (token) {
              localStorage.setItem("token", token);
              axios.defaults.headers.common["Authorization"] = token;
              commit("AUTH_SUCCESS", { token: token, user: resp.data.user });
            }
            resolve(resp);
          })
          .catch((err) => {
            commit("REQUEST_ERROR");
            localStorage.removeItem("token");
            reject(err);
          });
      });
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        commit("logout");
        localStorage.removeItem("token");
        delete axios.defaults.headers.common["Authorization"];
        resolve();
      });
    },
    postRequest({ commit }, dataParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(dataParams.url, dataParams.formData)
          .then((resp) => {
            commit("REQUEST_SUCCESS");
            commit("SET_DB_RESPONSE", resp.data);
            if (resp.data.message) {
              Vue.prototype.$notifier({
                message: resp.data.message,
                type: "info",
              });
            }
            resolve(resp);
          })
          .catch((err) => {
            if (err.response.status == 401) {
              this.dispatch("logout").then(() => {
                window.location.replace("/");
              });
            }
            commit("REQUEST_ERROR");
            reject(err);
          });
      });
    },
    getRequest({ commit }, getURL) {
      return new Promise((resolve, reject) => {
        axios
          .get(getURL)
          .then((resp) => {
            commit("REQUEST_SUCCESS");
            commit("SET_DB_RESPONSE", resp.data);
            if (resp.data.message) {
              Vue.prototype.$notifier({
                message: resp.data.message,
                type: "info",
              });
            }
            resolve(resp);
          })
          .catch((err) => {
            if (err.response.status == 401) {
              this.dispatch("logout").then(() => {
                window.location.replace("/");
              });
            }
            commit("REQUEST_ERROR");
            reject(err);
          });
      });
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
    authStatus: (state) => state.status,
    user: (state) => state.user,
    branch: (state) => state.branch,
  },
});
