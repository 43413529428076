export const reports = [
  {
    path: "/reports/general",
    name: "GeneralReports",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Reports/Index.vue"
      ),
    meta: {
      title: "Orders Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/purchase-orders",
    name: "OrdersReports",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Reports/PurchaseOrders.vue"
      ),
    meta: {
      title: "Orders Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/purchase-order-invoice/:reference",
    name: "OrdersInvoice",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Reports/OrderInvoice.vue"
      ),
    meta: {
      title: "Orders Invoice",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/store-status",
    name: "StoreStatus",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Reports/StoreStatus.vue"
      ),
    meta: {
      title: "Store Status Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/receive",
    name: "ReceiveReport",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Reports/Receive.vue"
      ),
    meta: {
      title: "Stock Receing Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/receive/:reference",
    name: "ReceiveVoucher",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Reports/ReceiveVoucher.vue"
      ),
    meta: {
      title: "Receive Voucher",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/transfer",
    name: "TransferReport",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Reports/Transfer.vue"
      ),
    meta: {
      title: "Stock Transfer Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/transfer/items",
    name: "ItemTransferReport",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Reports/ItemTransfer.vue"
      ),
    meta: {
      title: "Items Transfer Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/transfer/voucher/:reference",
    name: "TransferVoucher",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Reports/TransferVoucher.vue"
      ),
    meta: {
      title: "Transfer Voucher",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/purchase/items",
    name: "ItemPurchaseReport",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Reports/ItemPurchase.vue"
      ),
    meta: {
      title: "Items Purchase Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/store/:code/tracker",
    name: "ItemTracker",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Reports/ItemTracker.vue"
      ),
    meta: {
      title: "Item Tracking History",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/recipes-costing",
    name: "RecipesCosting",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Reports/RecipesCosting.vue"
      ),
    meta: {
      title: "Recipes Costing Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/suppliers/payments",
    name: "SupplierPayments",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Reports/SupplierPayments.vue"
      ),
    meta: {
      title: "Supplier Payments Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/spoiled-items",
    name: "SpoiledItemsReport",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Reports/SpoiledItems.vue"
      ),
    meta: {
      title: "Spoiled Items Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/production-sales",
    name: "ProductionSalesReport",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Reports/SalesReport.vue"
      ),
    meta: {
      title: "Production Sales Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/production-stock-status",
    name: "ProductionStoreStatus",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Production/Stock.vue"
      ),
    meta: {
      title: "Production Store Status",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/branches/store-status",
    name: "BranchStoreStatus",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Branches/Stock.vue"
      ),
    meta: {
      title: "Store Status",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/receiving-report",
    name: "BranchReceingReport",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Branches/ReceivesReport.vue"
      ),
    meta: {
      title: "Receiving Report",
      requiresAdminAuth: true,
    },
  },
];
