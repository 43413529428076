export const globalMethods = {
  data() {
    return {
      apiURL:
        process.env.NODE_ENV === "production"
          ? "https://mc.autoplusrw.com/api/uploads"
          : "http://127.0.0.1:8000/uploads",
      formTitle: null,
      formSubtitle: null,
      alertDefaults: {
        title: null,
        content: null,
        actionType: null,
        actionButton: null,
        classes: null,
        modalOpen: false,
      },
      choosenRow: {},
      modalOpen: false,
      filterKey: null,
      publicPath: process.env.BASE_URL,
    };
  },
  computed: {
    isUserLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    userPermissions() {
      if (this.$store.getters.user) {
        let role = this.$store.getters.user.role;
        if (role) return role.permissions;
      }
      return [];
    },
    appSettings() {
      let settings = this.$store.state.settings || {};
      return settings.result || {};
    },
    loggedUser() {
      return this.$store.getters.user || {};
    },
    userRole() {
      if (this.loggedUser.role) return this.loggedUser.role.name;
    },
    currentBranch() {
      return this.$store.getters.branch;
    },
  },
  methods: {
    formData(obj) {
      let formData = new FormData();
      for (let key in obj) {
        if (obj[key] !== null) {
          if (typeof obj[key] === "object")
            formData.append(key, JSON.stringify(obj[key]));
          else formData.append(key, obj[key]);
        }
      }
      return formData;
    },
    signout() {
      this.$store.dispatch("logout").then(() => {
        window.location.replace("/");
      });
    },
    closeAlert() {
      this.alertDefaults.modalOpen = false;
    },
    toggleModalOpen() {
      const elt = document.documentElement;
      if (elt.classList.contains("modal-open"))
        elt.classList.remove("modal-open");
      else elt.classList.add("modal-open");

      const el = document.body;
      if (el.classList.contains("modal-open"))
        el.classList.remove("modal-open");
      else el.classList.add("modal-open");
    },
    toggleModal() {
      this.toggleModalOpen();
      this.modalOpen = !this.modalOpen;
    },
    clearObject(obj) {
      for (let key in obj) {
        if (obj[key] === true) obj[key] = false;
        else if (obj[key] === false) continue;
        else obj[key] = null;
      }
      return obj;
    },
    selectRecord(record) {
      this.choosenRow = record;
    },
    isEqual(arrLen, key) {
      return key === arrLen - 1;
    },
    isEmpty(obj) {
      const isNULL = Object.values(obj).every((x) => x === null);
      return Object.keys(obj).length === 0 || isNULL === true;
    },
    addComma(num) {
      if (num) {
        if (num.toString().indexOf(".") > -1) num = Number(num).toFixed(2);
      }
      let regexp = /\B(?=(\d{3})+(?!\d))/g;
      return Number(num) ? num.toString().replace(regexp, ",") : 0;
    },
    incrementObjectKey(obj, key, max = null) {
      if (max) {
        if (Number(obj[key]) <= max) {
          obj[key] = Number(obj[key]) + 1;
        }
      } else {
        obj[key] = Number(obj[key]) + 1;
      }
    },
    decrementObjectKey(obj, key, min = 1) {
      if (Number(obj[key]) > min) {
        obj[key] = Number(obj[key]) - 1;
      }
    },
    removeComma(str) {
      return Number(str.replace(/,/g, ""));
    },
    uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      );
    },
    chatDate(str) {
      let options = {
        month: "short",
        day: "numeric",
        year: "numeric",
        timeZone: "Africa/Kigali",
      };
      let today = new Date(str);
      return today.toLocaleDateString("en-US", options);
    },
    chatMiniDate(str) {
      let options = {
        month: "short",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
        timeZone: "Africa/Kigali",
      };
      let today = new Date(str);
      return today.toLocaleDateString("en-US", options);
    },
    reloadPage() {
      window.location.reload();
    },
    togglePasswordVisibility(inputId) {
      let elt = document.getElementById(inputId);
      if (elt.type === "password") elt.type = "text";
      else elt.type = "password";
      if (document.getElementById("togglePassword")) {
        document
          .getElementById("togglePassword")
          .classList.toggle("os-icon-eye-off");
      }
    },
    showFilePicker(inputFileId) {
      document.getElementById(inputFileId).click();
    },
    handleEntityImageModify(args = {}) {
      this.$store.dispatch("SetEntityPostData", args).then(() => {
        document.getElementById("global-upload").click();
      });
    },
    hasPermissionTo(permission) {
      if (this.userRole == "ADMINISTRATOR") return true;
      return this.userPermissions.includes(permission);
    },
    empty(mixedVar) {
      let undef, key, i, len;
      const emptyValues = [undef, null, false, 0, "", "0"];
      for (i = 0, len = emptyValues.length; i < len; i++) {
        if (mixedVar === emptyValues[i]) {
          return true;
        }
      }
      if (typeof mixedVar === "object") {
        for (key in mixedVar) {
          if (Object.prototype.hasOwnProperty.call(mixedVar, key)) {
            return false;
          }
        }
        return true;
      }
      return false;
    },
    handleDeleteAlertModal(args) {
      this.$store.state.recordDeleter.entityName = args.tableName;
      this.$store.state.recordDeleter.url = args.url;
      if (!this.empty(args.media)) {
        this.$store.state.recordDeleter.media = { ...args.media };
      }
      if (!this.empty(args.callback)) {
        this.$store.state.recordDeleter.callback = args.callback;
      }
      this.$store.state.deleteAlert = {
        title: "Deleting a record",
        content:
          "Are you sure you want to delete this " +
          args.entityIdentifer +
          ": <b>" +
          args.entity +
          "?</b> <br/> If you continue, there is no way to undo change you have made.",
        actionButton: "Yes, delete",
        modalOpen: true,
      };
    },
    deletePaginationRecord() {
      let commitRef = "getRequest";
      let recordDeleter = this.$store.state.recordDeleter;
      let recordDeleterUrl = recordDeleter.url;
      let dispatchArgs = recordDeleterUrl;
      if (
        !this.empty(recordDeleter.media) &&
        !this.isEmpty(recordDeleter.media)
      ) {
        commitRef = "postRequest";
        dispatchArgs = {
          url: recordDeleterUrl,
          formData: this.formData({ media: recordDeleter.media }),
        };
      }
      this.$store.state.requestFlag = "deletingRecord";
      this.$store.dispatch(commitRef, dispatchArgs).then((response) => {
        if (!response.data.error) {
          this.$store.state.deleteAlert.modalOpen = false;
          let callback = this.$store.state.recordDeleter.callback;
          if (!this.empty(callback)) {
            callback();
            delete this.$store.state.recordDeleter.callback;
          } else window.location.reload();
        }
      });
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    handleArrayPush(array, newItem) {
      return [...[newItem], ...array];
    },
    handlePrint() {
      window.print();
    },
  },
};
