<template>
  <div id="left-topband" class="float-left">
    <div
      id="ember10"
      class="
        dropdown
        ember-view
        quik-add
        multi-col-dropdown
        float-left
        p-2
        rounded
      "
    >
      <div
        id="ember11"
        style="border-radius: 4px"
        class="
          dropdown-toggle
          no-caret
          ember-view
          add-new
          circle-box
          border-0
          cursor-pointer
        "
      >
        <div
          id="quick-create"
          class="tooltip-container ember-view"
          style="position: relative; width: 100%"
        >
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            viewBox="0 0 512 512"
            xml:space="preserve"
            class="icon icon-xs quick-plus"
          >
            <path
              d="M484 227H284V28c0-15.5-12.5-28-28-28s-28 12.5-28 28v199H28c-15.5 0-28 12.5-28 28v1c0 15.5 12.5 28 28 28h200v200c0 15.5 12.5 28 28 28s28-12.5 28-28V284h200c15.5 0 28-12.5 28-28v-1c0-15.5-12.5-28-28-28z"
            ></path>
          </svg>
        </div>
      </div>
      <div id="ember12" class="dropdown-menu ember-view mt-2">
        <ul class="list-inline">
          <li class="list-inline-item">
            <ul class="list-unstyled">
              <li class="header">
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0"
                  y="0"
                  viewBox="0 0 512 512"
                  xml:space="preserve"
                  class="icon icon-xs"
                >
                  <path
                    d="M208 0H16C7.2 0 0 7.2 0 16v196c0 8.8 7.2 16 16 16h192c8.8 0 16-7.2 16-16V16c0-8.8-7.2-16-16-16zm-19 196H35c-1.7 0-3-1.3-3-3V35c0-1.7 1.3-3 3-3h154c1.7 0 3 1.3 3 3v158c0 1.7-1.3 3-3 3zM496 0H304c-8.8 0-16 7.2-16 16v196c0 8.8 7.2 16 16 16h192c8.8 0 16-7.2 16-16V16c0-8.8-7.2-16-16-16zm-19 196.1H323c-1.7 0-3-1.3-3-3V35c0-1.7 1.3-3 3-3h154c1.7 0 3 1.3 3 3v158c0 1.7-1.3 3.1-3 3.1zM208 284H16c-8.8 0-16 7.2-16 16v196c0 8.8 7.2 16 16 16h192c8.8 0 16-7.2 16-16V300c0-8.8-7.2-16-16-16zm-19 196H35c-1.7 0-3-1.3-3-3V319c0-1.7 1.3-3 3-3h154c1.7 0 3 1.3 3 3v158c0 1.7-1.3 3-3 3zM496 284H304c-8.8 0-16 7.2-16 16v196c0 8.8 7.2 16 16 16h192c8.8 0 16-7.2 16-16V300c0-8.8-7.2-16-16-16zm-19 196.1H323c-1.7 0-3-1.3-3-3V319c0-1.7 1.3-3 3-3h154c1.7 0 3 1.3 3 3v158c0 1.7-1.3 3.1-3 3.1z"
                  ></path></svg
                >&nbsp;&nbsp;General
              </li>
              <li>
                <a href="#" data-ember-action="" data-ember-action-298="298"
                  >+ &nbsp;Add Users
                </a>
              </li>
              <li>
                <a id="ember299" class="ember-view" href="#/inventory/items/new"
                  >+ &nbsp;Item
                </a>
              </li>
              <li>
                <a
                  id="ember300"
                  class="ember-view"
                  href="#/inventory/itemgroups/new"
                  >+ &nbsp;Item Groups
                </a>
              </li>
              <!----><!---->
              <li>
                <a href="#" data-ember-action="" data-ember-action-301="301"
                  >+ &nbsp;Inventory Adjustments
                </a>
              </li>
              <!----><!----><!---->
            </ul>
          </li>
          <li class="list-inline-item">
            <ul class="list-unstyled">
              <li class="header">
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0"
                  y="0"
                  viewBox="0 0 512 512"
                  xml:space="preserve"
                  class="icon icon-xs"
                >
                  <path
                    d="M200 413.3c-24.3 0-43.8 19.6-43.8 43.8s19.6 43.8 43.8 43.8 43.8-19.6 43.8-43.8-19.6-43.8-43.8-43.8zM382.9 413.3c-24.3 0-43.8 19.6-43.8 43.8s19.6 43.8 43.8 43.8c24.2 0 43.8-19.6 43.8-43.8s-19.6-43.8-43.8-43.8zM508.6 105.7c-3-4-7.8-6.2-12.8-6.2H187.9c-8.9 0-16.3 7.3-16.3 16.3S179 132 187.9 132H475l-51.8 201.2H164.4L78.5 27.7c-2.1-6.8-8.4-11.5-15.5-11.5H16.2C7.3 16.2 0 23.5 0 32.4s7.3 16.3 16.3 16.3H51l85.8 305.5c2.1 6.8 8.4 11.5 15.5 11.5H436c7.5 0 14-5.1 15.7-12.4l59.7-233.7c1.3-4.9.2-9.9-2.8-13.9z"
                  ></path>
                  <path
                    d="M356.6 246.8c8.9 0 16.3-7.3 16.3-16.3 0-8.9-7.3-16.3-16.3-16.3H224.5c-8.9 0-16.3 7.3-16.3 16.3 0 8.9 7.3 16.3 16.3 16.3h132.1z"
                  ></path></svg
                >&nbsp;&nbsp;Sales
              </li>
              <li>
                <a href="#" data-ember-action="" data-ember-action-302="302"
                  >+ &nbsp;Customer
                </a>
              </li>
              <!----><!----><!---->
              <li>
                <a id="ember303" class="ember-view" href="#/invoices/new"
                  >+ &nbsp;Invoices
                </a>
              </li>
              <!----><!----><!---->
              <li>
                <a id="ember304" class="ember-view" href="#/salesreceipt/new"
                  >+ &nbsp;Sales Receipts
                </a>
              </li>
              <li>
                <a
                  id="ember305"
                  class="ember-view"
                  href="#/retainerinvoices/new"
                  >+ &nbsp;Retainer Invoices
                </a>
              </li>
              <li>
                <a id="ember306" class="ember-view" href="#/salesorders/new"
                  >+ &nbsp;Sales Order
                </a>
              </li>
              <!---->
              <li>
                <a id="ember307" class="ember-view" href="#/packages/new"
                  >+ &nbsp;Packages
                </a>
              </li>
              <li>
                <a
                  id="ember308"
                  class="ember-view"
                  href="#/paymentsreceived/new"
                  >+ &nbsp;Customer Payment
                </a>
              </li>
              <li>
                <a id="ember309" class="ember-view" href="#/creditnotes/new"
                  >+ &nbsp;Credit Notes
                </a>
              </li>
            </ul>
          </li>
          <li class="list-inline-item">
            <ul class="list-unstyled">
              <li class="header">
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0"
                  y="0"
                  viewBox="0 0 512 512"
                  xml:space="preserve"
                  class="icon icon-xs"
                >
                  <path
                    d="M428 128h-76V96c0-53-43-96-96-96s-96 43-96 96v32H84c-11 0-20 9-20 20v344c0 11 9 20 20 20h344c11 0 20-9 20-20V148c0-11-9-20-20-20zM192 96c0-35.3 28.7-64 64-64s64 28.7 64 64v32H192V96zm224 384H96V160h320v320z"
                  ></path>
                  <circle cx="176" cy="213" r="23"></circle>
                  <circle cx="334" cy="213" r="23"></circle></svg
                >&nbsp;&nbsp;Purchases
              </li>
              <li>
                <a href="#" data-ember-action="" data-ember-action-310="310"
                  >+ &nbsp;Vendor
                </a>
              </li>
              <!----><!---->
              <li>
                <a id="ember311" class="ember-view" href="#/bills/new"
                  >+ &nbsp;Bills
                </a>
              </li>
              <!---->
              <li>
                <a id="ember312" class="ember-view" href="#/purchaseorders/new"
                  >+ &nbsp;Purchase Orders
                </a>
              </li>
              <li>
                <a id="ember313" class="ember-view" href="#/paymentsmade/new"
                  >+ &nbsp;Vendor Payment
                </a>
              </li>
              <li>
                <a id="ember314" class="ember-view" href="#/vendorcredits/new"
                  >+ &nbsp;Vendor Credits
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div
      id="ember30"
      class="float-left dropdown recent-activities ember-view px-0 mx-1"
    >
      <div id="ember31" class="dropdown open ember-view p-2 rounded">
        <div
          id="ember32"
          class="dropdown-toggle no-caret ember-view cursor-pointer"
        >
          <div id="recent-activities" class="tooltip-container ember-view">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              class="icon icon-xlg align-middle d-block"
            >
              <path
                d="M.6 256.1c0-7.7.4-15.6 1.1-23.3.8-8.8 7.4-15.1 15.6-15.1 8.4 0 15.7 7 15.7 15 0 .4 0 .8-.1 1.2-.7 7.1-1 14.3-1 21.5 0 43.8 12.3 86 35.5 122.2 21.5 33.5 51.7 60.8 87.2 78.8 37.5 19 79.9 27 122.7 23.1 43.1-3.9 83.3-19.7 116.2-45.5 54.6-42.9 87.2-109.5 87.2-178.3 0-14.8-1.5-29.7-4.6-44.1-9.2-43.4-29.9-82.4-60.1-112.8-29.5-29.7-66.4-50.6-106.6-60.4-41.6-10.1-84.8-8.3-125 5.4-38.5 13.1-74.1 37.5-100.4 68.8l-1.3 1.6 62.5.5c8.7.1 15.3 6.8 15.3 15.8 0 4.4-1.7 8.5-4.6 11.4-2.8 2.8-6.6 4.3-10.9 4.2l-95-.8c-8.6-.1-15.6-7.1-15.6-15.6l.8-93.2c.1-8.8 6.9-15.3 15.8-15.3 8.8 0 15.5 6.6 15.5 15.3l-.4 48.8 1.7-1.9c3.2-3.6 6.6-7 9.9-10.2 34.8-33.7 78.8-56.8 127-66.9C253.5-3.8 303 .1 347.9 17.7c43.5 17 81 44.5 108.3 79.3 29.8 37.9 48.5 82.9 54.1 130 1.1 9.6 1.7 19.3 1.7 29 0 40.1-9.8 80.5-28.4 116.8-18.7 36.5-45.8 68.1-78.6 91.6-37.5 26.8-82 42.9-128.8 46.6-46.8 3.6-93.3-5.4-134.5-26-41.1-20.6-75.8-51.7-100.4-90C14.7 353.4.6 305.4.6 256.1z"
              ></path>
              <path
                d="M353.9 355.2c-2.5 0-4.9-.6-7.2-1.8l-98.6-49.2c-5.2-2.4-8.5-7.3-8.5-13.1v-148c0-8.4 7.1-14.7 16-14.7s16 6.3 16 14.7v137.7L362 326c6.3 3.2 9.3 9.6 7.8 16.3-1.8 7.5-8.5 12.9-15.9 12.9z"
              ></path>
            </svg>
          </div>
        </div>
        <div v-if="false" class="dropdown-menu show ember-view mt-2">
          <div class="text-center">
            <div class="loading text-center">
              <div class="load-circle1"></div>
              <div class="load-circle2"></div>
              <div class="load-circle3"></div>
              <div class="load-circle4"></div>
              <div class="load-circle5"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "LeftTools",
};
</script>
<style scoped>
svg {
  overflow: hidden;
}
*,
::after,
::before {
  box-sizing: border-box;
}
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}
ul ul {
  margin-bottom: 0;
}
a {
  color: #2485e8;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #2a74be;
  text-decoration: underline;
}
.list-inline,
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
.dropdown-menu.show {
  display: block;
}
.dropdown {
  position: relative;
}
.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  min-width: 100%;
}
svg {
  vertical-align: baseline;
}
.align-middle {
  vertical-align: middle !important;
}
.border-0 {
  border: 0 !important;
}
.rounded {
  border-radius: 0.25rem !important;
}
.d-block {
  display: block !important;
}
.float-left {
  float: left !important;
}
.mt-2 {
  margin-top: 5px !important;
}
.p-2 {
  padding: 5px !important;
}
.text-center {
  text-align: center !important;
}
.tooltip-container {
  position: relative;
}
svg.icon {
  width: 16px;
  height: 16px;
  fill: currentColor;
}
svg.icon.icon-xs {
  height: 12px;
  width: 12px;
}
svg.icon.icon-xlg {
  height: 20px;
  width: 20px;
}
.loading {
  margin-top: 20px;
}
.finance-app .top-band .quik-add {
  margin: 4px 10px 0 20px;
}
.top-band .dropdown .dropdown-toggle {
  color: #777;
}
.dropdown-toggle.no-caret:after {
  content: none;
}
a {
  cursor: pointer;
}
a:focus,
a:hover {
  outline: 0;
  text-decoration: none;
}
.cursor-pointer {
  cursor: pointer;
}
:focus::-webkit-input-placeholder {
  opacity: 0.5;
}
:focus:-moz-placeholder {
  opacity: 0.5;
}
:focus::-moz-placeholder {
  opacity: 0.5;
}
:focus:-ms-input-placeholder {
  opacity: 0.5;
}
a:hover {
  text-decoration: none;
}
.recent-activities .dropdown-menu {
  min-width: 240px;
}
.multi-col-dropdown .list-unstyled li.header {
  color: #999;
  font-size: 12px;
  text-transform: uppercase;
  padding: 20px 15px 12px;
}
.multi-col-dropdown .list-unstyled li a {
  display: block;
  padding: 7px 18px;
  color: #777666;
  white-space: nowrap;
}
.multi-col-dropdown .list-unstyled li a:hover {
  color: #268ddd;
}
.recent-activities {
  margin: 3px 22px 5px 10px;
}
.recent-activities .open {
  background-color: #e9e9e9;
}
.quik-add .list-inline {
  white-space: nowrap;
}
.quik-add .list-inline > li {
  vertical-align: top;
}
.quik-add .add-new {
  background-color: #268ddd;
  transition: all 0.5s;
}
.quik-add .add-new .quick-plus {
  fill: #fff;
  margin-top: 9px;
  width: 16px;
  height: 16px;
}
.circle-box {
  border: 1px solid #eee;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  text-align: center;
  line-height: 1.7;
}
.load-circle1,
.load-circle2,
.load-circle3,
.load-circle4,
.load-circle5 {
  width: 8px;
  height: 8px;
  background: grey;
  display: inline-block;
  border-radius: 20px;
  -webkit-animation: loader 1.5s infinite;
  animation: loader 1.5s infinite;
}
.load-circle2 {
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s;
}
.load-circle3 {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
.load-circle4 {
  -webkit-animation-delay: 0.75s;
  animation-delay: 0.75s;
}
.load-circle5 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.not-unified-theme :not(.template-page .hp-side-column)::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.quik-add .circle-box {
  background-color: #1f282d;
}
.list-inline li.list-inline-item ul > li{
  display: block;
}
@-webkit-keyframes loader {
  from {
    opacity: 1;
    scale: 1;
  }
  to {
    opacity: 0.25;
    scale: 0.3;
  }
}
@keyframes loader {
  from {
    opacity: 1;
    scale: 1;
  }
  to {
    opacity: 0.25;
    scale: 0.3;
  }
}
</style>
