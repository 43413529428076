export class Helpers {
  static timeDifference(target, current = new Date()) {
    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;
    var elapsed = target - current;
    var nume, unit;
    if (elapsed > 0) {
      if (elapsed < msPerMinute) {
        nume = Math.round(elapsed / 1000);
        unit = "second";
      } else if (elapsed < msPerHour) {
        nume = Math.round(elapsed / msPerMinute);
        unit = "minute";
      } else if (elapsed < msPerDay) {
        nume = Math.round(elapsed / msPerHour);
        unit = "hour";
      } else if (elapsed < msPerMonth) {
        nume = Math.round(elapsed / msPerDay);
        unit = "day";
      } else if (elapsed < msPerYear) {
        nume = Math.round(elapsed / msPerMonth);
        unit = "month";
      } else {
        nume = Math.round(elapsed / msPerYear);
        unit = "year";
      }
    } else {
      if (elapsed > -1 * msPerMinute) {
        nume = Math.round(elapsed / 1000);
        unit = "second";
      } else if (elapsed > -1 * msPerHour) {
        nume = Math.round(elapsed / msPerMinute);
        unit = "minute";
      } else if (elapsed > -1 * msPerDay) {
        nume = Math.round(elapsed / msPerHour);
        unit = "hour";
      } else if (elapsed > -1 * msPerMonth) {
        nume = Math.round(elapsed / msPerDay);
        unit = "day";
      } else if (elapsed > -1 * msPerYear) {
        nume = Math.round(elapsed / msPerMonth);
        unit = "month";
      } else {
        nume = Math.round(elapsed / msPerYear);
        unit = "year";
      }
    }
    const rtf = new Intl.RelativeTimeFormat();
    return rtf.format(nume, unit);
  }
}
