export const crud = [
  {
    path: "*",
    name: "error404",
    component: () =>
      import(/* webpackChunkName: "mainChuck" */ "../index/views/Error404.vue"),
    meta: {
      title: "Page Not Found",
    },
  },
  {
    path: "/",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "mainChuck" */ "../index/views/Login.vue"),
    meta: {
      title: "Login",
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Dashboard/Index.vue"
      ),
    meta: {
      title: "Dashboard",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/stock/categories",
    name: "Categories",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Stock/Categories.vue"
      ),
    meta: {
      title: "Item Types",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/stock/units",
    name: "Units",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Stock/Units.vue"
      ),
    meta: {
      title: "Items Units",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/lead/suppliers",
    name: "Suppliers",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Lead/Suppliers.vue"
      ),
    meta: {
      title: "Suppliers",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/lead/clients",
    name: "Clients",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Lead/Clients.vue"
      ),
    meta: {
      title: "Clients",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/stock/products",
    name: "Products",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Stock/Products.vue"
      ),
    meta: {
      title: "Product",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/stock/initialize",
    name: "ProductsInitializor",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Stock/Initializer.vue"
      ),
    meta: {
      title: "Initialize Products",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/stock/products/create/:reference?",
    name: "ProductCreator",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Stock/ProductCreator.vue"
      ),
    meta: {
      title: "Create Product",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/stock/products/receive/:reference?",
    name: "ProductReceive",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Stock/Receive.vue"
      ),
    meta: {
      title: "Receive Products",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/stock/products/deliver/:reference?",
    name: "StockDelivery",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Stock/Deliver.vue"
      ),
    meta: {
      title: "Stock Transfer",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/stock/products/spoiled/create",
    name: "Spoiled",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Stock/Spoiled.vue"
      ),
    meta: {
      title: "Spoiled Items",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/stock/purchase-orders",
    name: "PurchaseOrders",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Stock/PurchaseOrders.vue"
      ),
    meta: {
      title: "Purchase Orders",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/branches",
    name: "Branches",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Branches/Index.vue"
      ),
    meta: {
      title: "Branches Listing",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/branches/sales-item",
    name: "BranchItems",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Branches/SalesItems.vue"
      ),
    meta: {
      title: "Sales Items",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/branches/sales-item/create/:reference?",
    name: "BranchItemsCreator",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Branches/ItemCreator.vue"
      ),
    meta: {
      title: "Create Sales Items",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/branches/stock/auto-requisitions",
    name: "AutoRequisitions",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Branches/AutoRequisitions.vue"
      ),
    meta: {
      title: "Auto Requisitions",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/production/items",
    name: "ProductionItems",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Production/Index.vue"
      ),
    meta: {
      title: "Production Items",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/production/create/:reference?",
    name: "ProductionItemCreator",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Production/ItemCreator.vue"
      ),
    meta: {
      title: "Create Production Item",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/production/requisite",
    name: "ProductionMake",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Production/Create.vue"
      ),
    meta: {
      title: "Create Production Item",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/production/status",
    name: "ProductionStatus",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Production/Status.vue"
      ),
    meta: {
      title: "Production Status",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/production/sales/:reference?",
    name: "ProductionSales",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Production/Sales.vue"
      ),
    meta: {
      title: "Sales - Production",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/production/invoices",
    name: "ProductionInvoices",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Reports/SalesReport.vue"
      ),
    meta: {
      title: "Production Invoices",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/lead/employees",
    name: "Users",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Lead/Users.vue"
      ),
    meta: {
      title: "Employees",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/settings",
    name: "Settings",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Settings/General.vue"
      ),
    meta: {
      title: "General Settings",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/settings/users/privileges",
    name: "Permissions",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Settings/Permissions.vue"
      ),
    meta: {
      title: "Permissions Matrix",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/requesitions",
    name: "Requests",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Branches/Requests.vue"
      ),
    meta: {
      title: "Requesitions",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/requesitions/create",
    name: "CreateRequest",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Branches/Requester.vue"
      ),
    meta: {
      title: "Requests Items",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/finance/supplier/bulk-payment",
    name: "BulkPayment",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Finance/BulkPayment.vue"
      ),
    meta: {
      title: "Bulk Suppliers Payment",
      requiresAdminAuth: true,
    },
  }
];
