<template>
  <div class="nk-sidebar nk-sidebar-fixed sidebar-open">
    <div class="nk-sidebar-element nk-sidebar-head pl-2 py-1">
      <div class="nk-sidebar-brand">
        <a href="javascript:void(0)" class="logo-link nk-sidebar-logo"
          ><img
            class="logo-light logo-img"
            :src="`${apiURL}/${appSettings.site_logo}`"
            alt="logo" /><img
            class="logo-dark logo-img"
            :src="`${apiURL}/${appSettings.site_logo}`"
            alt="logo-dark"
        /></a>
      </div>
    </div>
    <div class="nk-sidebar-element">
      <div class="nk-sidebar-content">
        <div class="nk-sidebar-menu" data-simplebar="init">
          <div class="simplebar-wrapper" style="margin: -16px 0px -40px">
            <div class="simplebar-height-auto-observer-wrapper">
              <div class="simplebar-height-auto-observer"></div>
            </div>
            <div class="simplebar-mask">
              <div class="simplebar-offset" style="right: 0px; bottom: 0px">
                <div
                  class="simplebar-content-wrapper"
                  style="height: 100%; overflow: hidden scroll"
                >
                  <div class="simplebar-content" style="padding: 16px 0px 40px">
                    <ul class="nk-menu" v-if="empty(loggedUser.branch)">
                      <li
                        class="nk-menu-item"
                        v-if="hasPermissionTo('view_dashboard')"
                      >
                        <router-link
                          :to="{ name: 'Dashboard' }"
                          class="nk-menu-link"
                          ><span class="nk-menu-icon"
                            ><em class="icon ni ni-dashboard-fill"></em></span
                          ><span class="nk-menu-text"
                            >Dashboard</span
                          ></router-link
                        >
                      </li>
                      <li
                        class="nk-menu-item has-sub"
                        id="inventory-lite"
                        v-if="hasPermissionTo('manage_inventory')"
                      >
                        <router-link
                          :to="{ name: '' }"
                          class="nk-menu-link nk-menu-toggle"
                          ><span class="nk-menu-icon"
                            ><em class="icon ni ni-movie"></em></span
                          ><span class="nk-menu-text">Inventory</span
                          ><span
                            class="nk-menu-badge"
                            v-if="
                              hasNotifications([
                                'requisitions',
                                'purchase_orders',
                              ])
                            "
                            >{{
                              totalNotifications([
                                "requisitions",
                                "purchase_orders",
                              ])
                            }}</span
                          ></router-link
                        >
                        <ul class="nk-menu-sub">
                          <li
                            class="nk-menu-item"
                            v-if="hasPermissionTo('view_store_items')"
                          >
                            <router-link
                              :to="{ name: 'Products' }"
                              class="nk-menu-link"
                              ><span class="nk-menu-text"
                                >Items</span
                              ></router-link
                            >
                          </li>
                          <li
                            class="nk-menu-item"
                            v-if="hasPermissionTo('create_store_item')"
                          >
                            <router-link
                              :to="{ name: 'ProductCreator' }"
                              class="nk-menu-link"
                              ><span class="nk-menu-text"
                                >Create New Item</span
                              ></router-link
                            >
                          </li>
                          <li
                            class="nk-menu-item"
                            v-if="hasPermissionTo('initialize_stock')"
                          >
                            <router-link
                              :to="{ name: 'ProductsInitializor' }"
                              class="nk-menu-link"
                              ><span class="nk-menu-text"
                                >Initialize Quantity</span
                              ></router-link
                            >
                          </li>
                          <li
                            class="nk-menu-item"
                            v-if="hasPermissionTo('receive_store_items')"
                          >
                            <router-link
                              :to="{ name: 'ProductReceive' }"
                              class="nk-menu-link"
                              ><span class="nk-menu-text"
                                >Receive Items</span
                              ></router-link
                            >
                          </li>
                          <li
                            class="nk-menu-item"
                            v-if="hasPermissionTo('view_requisitions')"
                          >
                            <router-link
                              :to="{ name: 'Requests' }"
                              class="nk-menu-link"
                              ><span class="nk-menu-text">Requisitions</span>
                              <div class="nk-menu-badge nk-submenu-badge">
                                {{ notifications.requisitions || "" }}
                              </div>
                            </router-link>
                          </li>
                          <li
                            class="nk-menu-item"
                            v-if="hasPermissionTo('stock_delivery')"
                          >
                            <router-link
                              :to="{ name: 'StockDelivery' }"
                              class="nk-menu-link"
                              ><span class="nk-menu-text"
                                >Stock Transfer</span
                              ></router-link
                            >
                          </li>
                          <li
                            class="nk-menu-item"
                            v-if="hasPermissionTo('view_purchase_order')"
                          >
                            <router-link
                              :to="{ name: 'PurchaseOrders' }"
                              class="nk-menu-link"
                              ><span class="nk-menu-text">Purchase Orders</span>
                              <div class="nk-menu-badge nk-submenu-badge">
                                {{ notifications.purchase_orders || "" }}
                              </div></router-link
                            >
                          </li>
                          <li
                            class="nk-menu-item"
                            v-if="hasPermissionTo('stock_delivery')"
                          >
                            <router-link
                              :to="{ name: 'Spoiled' }"
                              class="nk-menu-link"
                              ><span class="nk-menu-text"
                                >Spoiled Items</span
                              ></router-link
                            >
                          </li>
                        </ul>
                      </li>

                      <li
                        class="nk-menu-item has-sub"
                        id="production-Menu"
                        v-if="hasPermissionTo('access_production')"
                      >
                        <router-link
                          :to="{ name: '' }"
                          class="nk-menu-link nk-menu-toggle"
                          ><span class="nk-menu-icon"
                            ><em class="icon ni ni-blogger-fill"></em></span
                          ><span class="nk-menu-text"
                            >Production</span
                          ></router-link
                        >
                        <ul class="nk-menu-sub">
                          <li
                            class="nk-menu-item"
                            v-if="hasPermissionTo('view_production_status')"
                          >
                            <router-link
                              :to="{ name: 'ProductionStatus' }"
                              class="nk-menu-link"
                              ><span class="nk-menu-text"
                                >Status</span
                              ></router-link
                            >
                          </li>
                          <li
                            class="nk-menu-item"
                            v-if="hasPermissionTo('view_production_items')"
                          >
                            <router-link
                              :to="{ name: 'ProductionItems' }"
                              class="nk-menu-link"
                              ><span class="nk-menu-text"
                                >Items</span
                              ></router-link
                            >
                          </li>
                          <li
                            class="nk-menu-item"
                            v-if="hasPermissionTo('create_new_item')"
                          >
                            <router-link
                              :to="{ name: 'ProductionItemCreator' }"
                              class="nk-menu-link"
                              ><span class="nk-menu-text"
                                >Add Item</span
                              ></router-link
                            >
                          </li>
                          <li
                            class="nk-menu-item"
                            v-if="hasPermissionTo('make_production')"
                          >
                            <router-link
                              :to="{ name: 'ProductionMake' }"
                              class="nk-menu-link"
                              ><span class="nk-menu-text"
                                >Make Production</span
                              ></router-link
                            >
                          </li>
                          <li
                            class="nk-menu-item"
                            v-if="hasPermissionTo('view_dispatch_report')"
                          >
                            <router-link
                              :to="{ name: 'ProductionInvoices' }"
                              class="nk-menu-link"
                              ><span class="nk-menu-text">
                                Invoices</span
                              ></router-link
                            >
                          </li>
                          <li
                            class="nk-menu-item"
                            v-if="hasPermissionTo('access_stock_transfer')"
                          >
                            <router-link :to="{ name: '' }" class="nk-menu-link"
                              ><span class="nk-menu-text"
                                >Stock Transfer</span
                              ></router-link
                            >
                          </li>
                        </ul>
                      </li>
                      <li
                        class="nk-menu-item has-sub"
                        id="branch-menu"
                        v-if="hasPermissionTo('access_branches_menu')"
                      >
                        <router-link
                          :to="{ name: '' }"
                          class="nk-menu-link nk-menu-toggle"
                          ><span class="nk-menu-icon"
                            ><em class="icon ni ni-cc-alt2"></em></span
                          ><span class="nk-menu-text"
                            >Branches</span
                          ></router-link
                        >

                        <ul class="nk-menu-sub">
                          <li
                            class="nk-menu-item"
                            v-if="hasPermissionTo('view_branches')"
                          >
                            <router-link
                              :to="{ name: 'Branches' }"
                              class="nk-menu-link"
                              ><span class="nk-menu-text"
                                >Branches List</span
                              ></router-link
                            >
                          </li>
                          <li
                            class="nk-menu-item"
                            v-if="hasPermissionTo('view_sales_items')"
                          >
                            <router-link
                              :to="{ name: 'BranchItems' }"
                              class="nk-menu-link"
                              ><span class="nk-menu-text"
                                >Sales Items</span
                              ></router-link
                            >
                          </li>
                          <li
                            class="nk-menu-item"
                            v-if="hasPermissionTo('create_sales_item')"
                          >
                            <router-link
                              :to="{ name: 'BranchItemsCreator' }"
                              class="nk-menu-link"
                              ><span class="nk-menu-text"
                                >Create Sales Item</span
                              ></router-link
                            >
                          </li>
                          <li
                            class="nk-menu-item"
                            v-if="hasPermissionTo('view_store_status')"
                          >
                            <router-link :to="{ name: '' }" class="nk-menu-link"
                              ><span class="nk-menu-text"
                                >Store Status</span
                              ></router-link
                            >
                          </li>
                          <li
                            class="nk-menu-item"
                            v-if="hasPermissionTo('access_auto_requisitions')"
                          >
                            <router-link :to="{ name: '' }" class="nk-menu-link"
                              ><span class="nk-menu-text">
                                Auto Requisitions</span
                              ></router-link
                            >
                          </li>
                        </ul>
                      </li>
                      <li
                        class="nk-menu-item has-sub"
                        id="lead-menu"
                        v-if="hasPermissionTo('access_leads_menu')"
                      >
                        <router-link
                          :to="{ name: '' }"
                          class="nk-menu-link nk-menu-toggle"
                          ><span class="nk-menu-icon"
                            ><em class="icon ni ni-users-fill"></em></span
                          ><span class="nk-menu-text">Lead</span></router-link
                        >
                        <ul class="nk-menu-sub">
                          <li
                            class="nk-menu-item"
                            v-if="hasPermissionTo('manage_users_roles')"
                          >
                            <router-link
                              :to="{ name: 'Users' }"
                              class="nk-menu-link"
                              ><span class="nk-menu-text"
                                >Users &amp; Roles</span
                              ></router-link
                            >
                          </li>
                          <li
                            class="nk-menu-item"
                            v-if="hasPermissionTo('manage_suppliers')"
                          >
                            <router-link
                              :to="{ name: 'Suppliers' }"
                              class="nk-menu-link"
                              ><span class="nk-menu-text"
                                >Suppliers</span
                              ></router-link
                            >
                          </li>
                          <li
                            class="nk-menu-item"
                            v-if="hasPermissionTo('manage_clients')"
                          >
                            <router-link
                              :to="{ name: 'Clients' }"
                              class="nk-menu-link"
                              ><span class="nk-menu-text"
                                >Clients</span
                              ></router-link
                            >
                          </li>
                        </ul>
                      </li>

                      <li
                        class="nk-menu-item has-sub"
                        id="accounting-menu"
                        v-if="hasPermissionTo('access_accounting_menu')"
                      >
                        <router-link
                          :to="{ name: '' }"
                          class="nk-menu-link nk-menu-toggle"
                          ><span class="nk-menu-icon"
                            ><em class="icon ni ni-reports"></em></span
                          ><span class="nk-menu-text">Finance</span
                          ><span
                            class="nk-menu-badge"
                            v-if="hasNotifications(['suppliers_payments'])"
                            >{{
                              totalNotifications(["suppliers_payments"])
                            }}</span
                          ></router-link
                        >
                        <ul class="nk-menu-sub">
                          <li
                            class="nk-menu-item"
                            v-if="hasPermissionTo('services_record')"
                          >
                            <router-link :to="{ name: '' }" class="nk-menu-link"
                              ><span class="nk-menu-text"
                                >Services Record</span
                              ></router-link
                            >
                          </li>
                          <li
                            class="nk-menu-item"
                            v-if="hasPermissionTo('supplier_payment')"
                          >
                            <router-link
                              :to="{ name: 'SupplierPayments' }"
                              class="nk-menu-link"
                              ><span class="nk-menu-text"
                                >Suppliers Payment</span
                              >
                              <div class="nk-menu-badge nk-submenu-badge">
                                {{ notifications.suppliers_payments || "" }}
                              </div></router-link
                            >
                          </li>
                        </ul>
                      </li>

                      <li
                        class="nk-menu-item"
                        id="reports-menu"
                        v-if="hasPermissionTo('view_reports_menu')"
                      >
                        <router-link
                          :to="{ name: 'GeneralReports' }"
                          class="nk-menu-link"
                          ><span class="nk-menu-icon"
                            ><em class="icon ni ni-growth-fill"></em></span
                          ><span class="nk-menu-text">Reports</span
                          ><span
                            class="nk-menu-badge"
                            v-if="hasNotifications(['pending_orders'])"
                            >{{ totalNotifications(["pending_orders"]) }}</span
                          ></router-link
                        >
                      </li>

                      <li
                        class="nk-menu-item has-sub"
                        id="settings-menu"
                        v-if="hasPermissionTo('access_settings_menu')"
                      >
                        <router-link
                          :to="{ name: '' }"
                          class="nk-menu-link nk-menu-toggle"
                          ><span class="nk-menu-icon"
                            ><em class="icon ni ni-setting"></em></span
                          ><span class="nk-menu-text"
                            >Settings</span
                          ></router-link
                        >
                        <ul class="nk-menu-sub">
                          <li
                            class="nk-menu-item"
                            v-if="hasPermissionTo('general')"
                          >
                            <router-link
                              :to="{ name: 'Settings' }"
                              class="nk-menu-link"
                              ><span class="nk-menu-text"
                                >General</span
                              ></router-link
                            >
                          </li>
                          <li
                            class="nk-menu-item"
                            v-if="hasPermissionTo('permissions_matrix')"
                          >
                            <router-link
                              :to="{ name: 'Permissions' }"
                              class="nk-menu-link"
                              ><span class="nk-menu-text"
                                >Permissions Matrix</span
                              ></router-link
                            >
                          </li>
                          <li
                            class="nk-menu-item"
                            v-if="hasPermissionTo('product_types')"
                          >
                            <router-link
                              :to="{ name: 'Categories' }"
                              class="nk-menu-link"
                              ><span class="nk-menu-text"
                                >Product Types</span
                              ></router-link
                            >
                          </li>
                          <li
                            class="nk-menu-item"
                            v-if="hasPermissionTo('products_units')"
                          >
                            <router-link
                              :to="{ name: 'Units' }"
                              class="nk-menu-link"
                              ><span class="nk-menu-text"
                                >Products Units</span
                              ></router-link
                            >
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul class="nk-menu" v-else>
                      <li class="nk-menu-item">
                        <router-link :to="{ name: '' }" class="nk-menu-link"
                          ><span class="nk-menu-icon"
                            ><em class="icon ni ni-dashboard-fill"></em></span
                          ><span class="nk-menu-text"
                            >Dashboard</span
                          ></router-link
                        >
                      </li>
                      <li class="nk-menu-item">
                        <router-link
                          :to="{ name: 'BranchItems' }"
                          class="nk-menu-link"
                          ><span class="nk-menu-icon"
                            ><em class="icon ni ni-cart-fill"></em></span
                          ><span class="nk-menu-text"
                            >Sales Items</span
                          ></router-link
                        >
                      </li>
                      <li class="nk-menu-item">
                        <router-link
                          :to="{ name: 'BranchItemsCreator' }"
                          class="nk-menu-link"
                          ><span class="nk-menu-icon"
                            ><em class="icon ni ni-plus-round"></em></span
                          ><span class="nk-menu-text"
                            >Create Sales Item</span
                          ></router-link
                        >
                      </li>
                      <li class="nk-menu-item">
                        <router-link
                          :to="{ name: 'Requests' }"
                          class="nk-menu-link"
                          ><span class="nk-menu-icon"
                            ><em class="icon ni ni-trend-up"></em></span
                          ><span class="nk-menu-text"
                            >Requisitions</span
                          ></router-link
                        >
                      </li>
                      <li class="nk-menu-item">
                        <router-link
                          :to="{ name: 'AutoRequisitions' }"
                          class="nk-menu-link"
                          ><span class="nk-menu-icon"
                            ><em class="icon ni ni-tranx"></em></span
                          ><span class="nk-menu-text">
                            Auto Requisitions</span
                          ></router-link
                        >
                      </li>
                      <li
                        class="nk-menu-item"
                        id="reports-menu"
                      >
                        <router-link
                          :to="{ name: 'GeneralReports' }"
                          class="nk-menu-link"
                          ><span class="nk-menu-icon"
                            ><em class="icon ni ni-growth-fill"></em></span
                          ><span class="nk-menu-text"
                            >Reports</span
                          ></router-link
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="simplebar-placeholder"
              style="width: auto; height: 585px"
            ></div>
          </div>
          <div
            class="simplebar-track simplebar-horizontal"
            style="visibility: hidden"
          >
            <div
              class="simplebar-scrollbar"
              style="width: 0px; display: none"
            ></div>
          </div>
          <div
            class="simplebar-track simplebar-vertical"
            style="visibility: visible"
          >
            <div
              class="simplebar-scrollbar"
              style="
                height: 81px;
                transform: translate3d(0px, 138px, 0px);
                display: block;
              "
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "SideBar",
  watch: {
    $route() {
      var activeLink = document.querySelector(
        "a[href='" + location.pathname + "']"
      );
      if (activeLink) {
        let parent = activeLink.closest(".nk-menu-item.has-sub");
        if (parent) {
          parent.classList.add("active");
        }
      }
    },
  },
  updated() {
    this.$nextTick(() => {
      [...this.$el.querySelectorAll(".nk-menu-item.has-sub > a")].forEach(
        (elt) => {
          var parent = elt.parentElement;
          elt.addEventListener("click", () => {
            [...this.$el.querySelectorAll(".nk-menu-item.has-sub")].forEach(
              (li) => {
                if (li != parent) li.classList.remove("active");
              }
            );
            parent.classList.toggle("active");
          });
        }
      );
    });
  },
  created() {
    this.$store
      .dispatch("getRequest", "shared/notifications")
      .then((response) => {
        if (response.data.status) {
          this.$store.commit("SET_MENU_NOTIFICATIONS", response.data.records);
        }
      });
  },
  computed: {
    ...mapState({
      notifications: (state) => state.notifications,
    }),
  },
  methods: {
    hasNotifications(keys) {
      return keys.some((item) => this.notifications[item] != 0);
    },
    totalNotifications(keys) {
      return keys.reduce(
        (prev, next) => prev + Number(this.notifications[next]),
        0
      );
    },
  },
};
</script>
<style>
body,
html {
  font-size: 15px;
  background: #fff;
}
.logo-img {
  max-height: 70px;
}
@media (min-width: 1200px) {
  .nk-sidebar {
    width: 200px;
  }
  .preloader {
    left: 200px;
    top: 63px;
  }
  .preloader .preloader-inner {
    left: calc(50% - 200px);
  }
}

@media (max-width: 1200px) {
  .logo-img {
    max-height: 60px;
  }
  .nk-sidebar-overlay {
    z-index: -1;
    left: 200px;
  }
}
.nk-sidebar {
  width: 200px;
}
.nk-sidebar + .nk-wrap,
.nk-sidebar-overlay + .nk-wrap {
  padding-left: 200px;
}
.nk-sidebar + .nk-wrap > .nk-header-fixed,
.nk-sidebar-overlay + .nk-wrap > .nk-header-fixed {
  left: 200px;
}
.nk-menu-icon .icon {
  font-size: 18px;
}
.nk-menu-item {
  padding: 3px 0;
}
.nk-menu-icon {
  width: 22px;
}
.nk-menu-link {
  font-size: 13px;
  font-weight: 500;
}
.nk-menu-link,
.nk-menu-toggle:after {
  color: #00123e;
}
.nk-sidebar {
  background: #efefef;
}
.nk-header.is-light:not([class*="bg-"]) {
  background: #f7f7f7;
}
.nk-menu-link:hover,
.active > .nk-menu-link {
  color: #fff;
  background: #00123e;
  border-radius: 0;
}
.nk-menu-link:hover .nk-menu-icon,
.nk-menu-item.active > .nk-menu-link .nk-menu-icon,
.nk-menu-item.current-menu > .nk-menu-link .nk-menu-icon {
  color: #fff;
}

.nk-menu-link,
.nk-menu-toggle:after {
  color: #00123e;
}
.nk-sidebar-head {
  width: 200px;
}
</style>
