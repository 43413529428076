<template>
  <div class="nk-footer">
    <div class="container-fluid">
      <div class="nk-footer-wrap">
        <div class="nk-footer-copyright">
          © {{ new Date().getFullYear() }} {{ appSettings.site_name }}
        </div>
        <div class="nk-footer-links">
          <ul class="nav nav-sm">
            <li class="nav-item">
              <a class="nav-link" href="javascript:void(0)">Terms</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="javascript:void(0)">Privacy</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="javascript:void(0)">Help</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Footer",
};
</script>
