import Vue from "vue";
import Router from "vue-router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import store from "../store";
import { crud } from "./crud";
import { reports } from "./reports";
const options = {
  latencyThreshold: 200,
  router: true,
  http: false,
};
Vue.use(NProgress, options);
Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [...crud, ...reports],
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  NProgress.start();
   if (to.matched.some((record) => record.meta.requiresAdminAuth)) {
    if (store.getters.isLoggedIn) {
      next();
      return;
    }
    window.location.replace("/");
  } else {
    next();
  }
});

const DEFAULT_TITLE = "Inventory";
router.afterEach((to) => {
  NProgress.done();
  Vue.nextTick(() => {
    document.title = to.meta.title + " | " + DEFAULT_TITLE || DEFAULT_TITLE;
    if (store.state.drawerOpen) store.state.drawerOpen = false;
    [...document.querySelectorAll("div.dropdown-menu")].forEach((elt) => {
      elt.classList.remove("show");
    });
  });
});

export default router;
