import Vue from "vue";
import App from "./App.vue";
import "../registerServiceWorker";
import store from "../store";
import router from "../router";
import VeeValidate from "vee-validate";
import axios from "axios";
import VueAxios from "vue-axios";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
//Internal stuff created by me
import { globalMethods } from "../_helpers/global-methods.js";
import BootstrapModal from "@/components/BootstrapModal.vue";
import OffCanvas from "@/components/OffCanvas.vue";
import BootstrapAlert from "@/components/BootstrapAlert.vue";
import WolfButton from "@/components/WolfButton.vue";
import FileUpload from "@/components/FileUpload";
import alert from "@/components/alert.js";
import notifier from "@/components/notifier.js";
import LoadScript from "vue-plugin-load-script";
Vue.prototype.$http = axios;
const token = localStorage.getItem("token");
if (token) {
  Vue.prototype.$http.defaults.headers.common["Authorization"] =
    "Bearer " + token;
}

axios.interceptors.request.use(
  (config) => {
    NProgress.start();
    store.state.isLoading = true;
    return config;
  },
  (error) => {
    NProgress.done();
    store.state.isLoading = false;
    store.state.requestFlag = null;
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    NProgress.done();
    if (response.data) {
      store.state.isLoading = false;
      store.state.requestFlag = null;
    }
    return response;
  },
  (error) => {
    NProgress.done();
    store.state.isLoading = false;
    store.state.requestFlag = null;
    return Promise.reject(error);
  }
);

const options = {
  latencyThreshold: 200,
  router: true,
  http: true,
};

Vue.use(NProgress, options);
Vue.use(VueAxios, axios);
Vue.use(VeeValidate);
Vue.use(LoadScript);
Vue.use(alert, { store });
Vue.use(notifier);
Vue.mixin(globalMethods);
Vue.component("bootstrap-modal", BootstrapModal);
Vue.component("off-canvas", OffCanvas);
Vue.component("bootstrap-alert", BootstrapAlert);
Vue.component("wolf-button", WolfButton);
Vue.component("file-upload", FileUpload);
Vue.config.productionTip = false;

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
