export default {
  install(Vue) {
    Vue.prototype.$notifier = (options) => {
      var icon = "icon ni ni-info-fill";
      if (options.icon) {
        icon = options.icon;
      }
      const toast = `<div id="toast-container" class="toast-top-right">
      <div class="toastr toast-${options.type}" aria-live="polite" style="">
        <span class="btn-trigger toast-close-button" role="button">Close</span>
        <div class="toast-message">
          <span class="toastr-icon"><em class="${icon}"></em></span>
          <div class="toastr-text">
            ${options.message}
          </div>
        </div>
      </div>
    </div>`;
      var div = document.createElement("div");
      div.innerHTML = toast;
      document.body.prepend(div);
      setTimeout(() => {
        div.remove();
      }, 4000);
    };
  },
};
