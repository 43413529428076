<template>
  <button
    :class="classes"
    :type="buttonType"
    @click="
      $store.state.requestFlag = activator;
      $emit('btn-role');
    "
    :disabled="$store.state.isLoading || disabler"
  >
    <template
      v-if="$store.state.isLoading && $store.state.requestFlag === activator"
    >
      <p id="loading"><span></span><span></span><span></span></p>
    </template>
    <template v-else>
      <span v-if="caption != '' && caption != null">{{ caption }}</span>
      <slot name="caption" v-else></slot>
    </template>
  </button>
</template>
<script>
export default {
  name: "WolfButton",
  data: () => ({ axiosRequestings: false }),
  props: {
    caption: {
      type: String,
      required: false,
      default: null,
    },
    loadText: {
      type: String,
      default: "Please wait...",
      required: false,
    },
    classes: {
      type: String,
      required: false,
      default: "btn btn-lg btn-primary btn-block",
    },
    buttonType: {
      type: String,
      default: "button",
    },
    activator: {
      type: String,
      required: true,
    },
    disabler: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="css" scoped>
@-webkit-keyframes opacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes opacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

#loading {
  text-align: center;
  margin: 0;
}

#loading span {
  display: inline-block;
  width: 0.6125rem;
  height: 0.6125rem;
  margin: 0 0.3125rem;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0.5;
  vertical-align: middle;
  -webkit-animation-name: opacity;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;

  -moz-animation-name: opacity;
  -moz-animation-duration: 1s;
  -moz-animation-iteration-count: infinite;
}

#loading span:nth-child(2) {
  -webkit-animation-delay: 100ms;
  -moz-animation-delay: 100ms;
}

#loading span:nth-child(3) {
  -webkit-animation-delay: 300ms;
  -moz-animation-delay: 300ms;
}
</style>
