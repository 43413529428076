<template>
  <div class="nk-header nk-header-fixed is-light pl-1">
    <div class="nk-header-wrap">
      <div class="nk-header-search">
        <left-tools></left-tools>
        <zoho-search></zoho-search>
        <div class="w-100 text-center" v-if="false">
          <ul class="" v-if="!empty(currentUser.branch)">
            <li class="dropdown user-dropdown d-block" style="cursor: pointer">
              <img
                :alt="currentUser.branch.name"
                :src="`${publicPath}img/icons8_company_500px.png`"
                class="img rounded img-fluid"
                style="width: 38px"
              />
              <p class="h6 mb-0">{{ currentUser.branch.name }}</p>
            </li>
          </ul>
        </div>
      </div>
      <div class="nk-header-tools">
        <ul class="nk-quick-nav">
          <li class="dropdown notification-dropdown" id="notification-dropdown">
            <a
              href="javascript:void(0)"
              class="dropdown-toggle nk-quick-nav-icon"
              data-toggle="dropdown"
              aria-expanded="false"
              @click.prevent="showNotifications"
              ><div
                class="icon-status"
                :class="{ 'icon-status-info': hasNotifications }"
              >
                <em class="icon ni ni-bell"></em></div
            ></a>
            <div
              class="dropdown-menu dropdown-menu-xl dropdown-menu-right"
              style=""
              :class="{ show: notification }"
            >
              <div class="dropdown-head">
                <span class="sub-title nk-dropdown-title">Notifications</span
                ><a href="javascript:void(0)" v-show="false"
                  >Mark All as Read</a
                >
              </div>
              <div class="dropdown-body">
                <div class="nk-notification">
                  <a
                    class="nk-notification-item dropdown-inner"
                    v-for="(item, i) in notifications"
                    :key="'notification' + i"
                    :href="item.data.notification.link"
                  >
                    <div class="nk-notification-icon">
                      <em
                        :class="`icon icon-circle ${notificationIcon(
                          item.data.notification.event
                        )}
                          `"
                      ></em>
                    </div>
                    <div class="nk-notification-content">
                      <div class="nk-notification-text">
                        {{ item.data.notification.message }}
                      </div>
                      <div class="nk-notification-time">
                        {{ Helpers.timeDifference(new Date(item.created_at)) }}
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div class="dropdown-foot center">
                <a href="javascript:void(0)">View All</a>
              </div>
            </div>
          </li>
          <li class="dropdown user-dropdown" id="user-dropdown">
            <a
              href="javascript:void(0)"
              class="dropdown-toggle mr-n1"
              data-toggle="dropdown"
              aria-expanded="false"
              @click.prevent="userDropdown = !userDropdown"
              ><div class="user-toggle">
                <div class="user-avatar sm">
                  <em class="icon ni ni-user-alt"></em>
                </div>
                <div class="user-info d-none d-xl-block">
                  <div class="user-status user-status-active">
                    {{ userRole }}
                  </div>
                  <div class="user-name dropdown-indicator">
                    {{ currentUser.name }}
                  </div>
                </div>
              </div></a
            >
            <div
              class="dropdown-menu dropdown-menu-md dropdown-menu-right"
              style=""
              :class="{ show: userDropdown }"
            >
              <div
                class="
                  dropdown-inner
                  user-card-wrap
                  bg-lighter
                  d-none d-md-block
                "
              >
                <div class="user-card">
                  <avatar
                    :name="`${currentUser.first_name} ${
                      currentUser.last_name || ''
                    }`"
                  ></avatar>
                  <div class="user-info">
                    <span class="lead-text">{{ `${currentUser.name}` }}</span
                    ><span class="sub-text">{{ currentUser.email }}</span>
                  </div>
                </div>
              </div>
              <div class="dropdown-inner">
                <ul class="link-list">
                  <li>
                    <router-link :to="{ name: '' }"
                      ><em class="icon ni ni-user-alt"></em
                      ><span>View Profile</span></router-link
                    >
                  </li>
                  <li>
                    <router-link :to="{ name: '' }"
                      ><em class="icon ni ni-setting-alt"></em
                      ><span>Account Setting</span></router-link
                    >
                  </li>
                  <li>
                    <router-link :to="{ name: '' }"
                      ><em class="icon ni ni-activity-alt"></em
                      ><span>Login Activity</span></router-link
                    >
                  </li>
                </ul>
              </div>
              <div class="dropdown-inner">
                <ul class="link-list">
                  <li>
                    <a href="javascript:void(0)" @click.prevent="signout"
                      ><em class="icon ni ni-signout"></em
                      ><span>Sign out</span></a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <branch-selector v-if="modalOpen" @closed="toggleModal"></branch-selector>
  </div>
</template>
<script>
import Avatar from "@/components/Avatar";
import { mapState } from "vuex";
import BranchSelector from "./BranchSelector.vue";
import { Helpers } from "@/Helpers";
import ZohoSearch from "./ZohoSearch.vue";
import LeftTools from "./LeftTools.vue";
export default {
  name: "NavBar",
  components: {
    Avatar,
    BranchSelector,
    ZohoSearch,
    LeftTools,
  },
  data: () => ({
    userDropdown: false,
    notification: false,
    branchesDropdown: false,
    hasReadAll: false,
    Helpers,
  }),
  computed: {
    ...mapState({
      lockedBranch: (state) => state.branch,
    }),
    currentUser() {
      return this.$store.getters.user;
    },
    userRole() {
      if (this.currentUser.role) return this.currentUser.role.name;
      else return "";
    },
    notifications() {
      return this.currentUser.alerts || [];
    },
    hasNotifications() {
      return (
        this.notifications.some((item) => item.read_at == null) &&
        !this.hasReadAll
      );
    },
  },
  methods: {
    showNotifications() {
      this.notification = !this.notification;
      if (this.hasNotifications) {
        this.$store.commit("SET_REQUEST_FLAG", "READING_NOTIFICATIONS");
        this.$store
          .dispatch("getRequest", "auth/notifications/read")
          .then(() => {
            this.hasReadAll = true;
          });
      }
    },
    notificationIcon(event) {
      var result;
      switch (event) {
        case "StockTransfer":
          result = "bg-success ni-building-fill";
          break;
        case "RequisitionUpdated":
          result = "bg-primary-dim ni-reload";
          break;
        default:
          result = "bg-primary ni ni-trend-up";
      }
      return result;
    },
  },
};
</script>
